import {Button, Card, CardContent, Container, Typography, Unstable_Grid2 as Grid} from "@mui/material";
import {PropsWithChildren} from "react";
import {SimpleBackgroundLayout} from "@feg/ui/src/common/layout/SimpleBackgroundLayout";

export function Root() {
    return (
        <SimpleBackgroundLayout>

            <Typography variant={'h1'} color={'white'}>Headigital</Typography>
            <Container maxWidth={'md'}>
                <Grid container spacing={5} width={'100%'}>
                    <Grid xs={12} md={4}>
                        <LandingCard>
                            <Typography variant={'h4'} align={"center"}>Admin</Typography>
                            <Typography variant={"body1"}>The admin dashboard is where you can
                                manage your projects, users and more.</Typography>
                            <Button href={'https://admin.headigital.app'} target={"_blank"} sx={{color: "inherit"}}
                                    fullWidth>Open Dashboard</Button>
                        </LandingCard>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <LandingCard>
                            <Typography variant={'h4'} align={"center"}>Field</Typography>
                            <Typography variant={"body1"} flexGrow={1} minHeight={"0px"}>For researchers to enter
                                their projects
                                and interviews.</Typography>
                            <Button href={'https://field.headigital.app'} target={"_blank"} sx={{color: "inherit"}}
                                    fullWidth>Open Dashboard</Button>
                        </LandingCard>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <LandingCard>
                            <Typography variant={'h4'} align={"center"}>Resolve</Typography>
                            <Typography variant={"body1"} flexGrow={1}>For teamleader and admins to enter their
                                projects
                                and interviews.</Typography>
                            <Button href={'https://resolve.headigital.app'} target={"_blank"}
                                    sx={{color: "inherit"}} fullWidth>Open Dashboard</Button>
                        </LandingCard>
                    </Grid>
                </Grid>
            </Container>
        </SimpleBackgroundLayout>
    )
}

function LandingCard({children}: PropsWithChildren) {
    return (
        <Card elevation={10} sx={{
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.8)',
        }}>
            <CardContent sx={{
                flex: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}>
                {children}
            </CardContent>
        </Card>
    )
}
