import {appNhostClient} from "../lib/nhost/appNhostClient";
import {NhostProvider} from "@nhost/react";
import {PropsWithChildren} from "react";
import AppThemeProvider from "../theme/theme";
import AppGlobalStyles from "../theme/theme/AppGlobalStyles";

export function BasicAppProvider({children}: PropsWithChildren) {
    return (
        <NhostProvider nhost={appNhostClient}>
            <AppThemeProvider>
                <AppGlobalStyles/>
                {children}
            </AppThemeProvider>
        </NhostProvider>
    )
}