import {createRoot} from 'react-dom/client'
import '@fontsource-variable/inter';
import {StrictMode} from "react";
import {Root} from "./Root";
import {BasicAppProvider} from "@feg/ui/src/vite/BasicAppProvider";

// LicenseInfo.setLicenseKey('7bef5ee5e5f7911da8e5f428bfb34756Tz03NDAyOCxFPTE3MjU1MDU0NjUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

createRoot(document.getElementById('app')!).render(
    <StrictMode>
        <BasicAppProvider>
            <Root/>
        </BasicAppProvider>
    </StrictMode>
    // <RouterProvider
    // <RouterProvider router={router}/>
)